import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import VitaminMainCTA from "../components/vitaminMainCTA";
import Follow from "../components/follow";

const BlogPage = ({ data }) => {
  const [lang, setLang] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );

  const blog = data.contentfulBlogPost;
  const readingTime = Math.round(
    blog.childContentfulBlogPostTextRichTextNode.fields.readingTime.minutes
  );
  const reading = data.allLabels.edges.find((el) => el.node.label === "reading-time")
  console.log("dfs", blog.textInEnglish.json)
  return (
    <Layout>
      <SEO
        title={blog.title}
        ogimage={
          "https:" +
          blog.mainImage.file.url +
          "?w=1200&h=630&q=90&fit=pad&fm=jpg&bg=rgb:E9EBEE"
        }
      />
      <div
        className="fullWidthPink ingredientCover mobileContainer blogCover"
        style={{
          backgroundImage: `url('${blog.mainImage.file.url + "?h=1168"}')`,
        }}
      >
        <div className="shadowOverlay">
          <div className="container">
            <div className="ingredientHeader">
              <div className="ingredientHeaderLeft">
                <p className="readTime">
                  {readingTime} {readingTime === 1 ? "λεπτό" : lang === "gr" ? lang === "gr" ? "λεπτά" : "minute" : "minutes"} {reading && reading.node[lang]}
                </p>
                <h1>{lang === "gr" ? blog.title : blog.titleInEnglish}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container containerBlogText">
        {documentToReactComponents(lang === "gr" ? blog.text.json : blog.textInEnglish.json)}
      </div>
      <br />
      <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="s9-class-container"></div>
      </div>
      <div className="hr bloghr">&nbsp;</div>
      <VitaminMainCTA data={data.allLabels.edges} />
      <Follow lang={lang} data={data.allLabels.edges} />
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query($id: String!) {
    allLabels {
      edges {
        node {
          label
          gr
          en
        }
      }
    }
    contentfulBlogPost(id: { eq: $id }) {
      title
      titleInEnglish
      mainImage {
        file {
          url
          fileName
        }
        title
      }
      createdAt
      date
      text {
        json
      }
      textInEnglish{
        json
      }
      childContentfulBlogPostTextRichTextNode {
        fields {
          readingTime {
            minutes
          }
        }
      }
    }
  }
`;
